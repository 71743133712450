import React from "react";
import { Container, Col, Row } from "react-bootstrap";
const HeroSwitch = (props) => {
    return (
        <div className="green-bg hero-section">
            <Container>
                <Row>
                    <Col sm={12} className="mb-80 mt-80">
                        <h1>Simple-fast-free<br />no excuse not<br />to switch!</h1>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default HeroSwitch;
